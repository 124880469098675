.events-form-container {
  display: flex;
  width: 90%;
  max-width: 1200px;
  height: auto; /* Allow height to adjust based on content */
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.events-form-left,
.events-form-right {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}

.events-form-left {
  background: url('../../assets/eventform.jpeg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.events-form-left h2 {
  font-size: 1.7em;
  font-weight: bold;
  margin: 0;
}

.events-form-left p {
  font-size: 1em;
  margin-top: 8px;
  max-width: 80%;
}

.events-form-right {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.events-form-right h2 {
  font-size: 1.7em;
  margin-bottom: 35px;
  text-align: center;
}

.form-group {
  margin-bottom: 12px;
}

.form-group label {
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 0.9em;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9em;
  box-sizing: border-box;
  resize: vertical;
  height: 34px;
  min-height: 34px;
  overflow-y: auto;
}

.form-group:last-of-type textarea {
  height: 90px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #FDD301;
  color: black;
  font-weight: bold;
  font-size: 0.95em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #e6c200;
}

@media (max-width: 900px) {
  .events-form-container {
    flex-direction: column;
  }

  .events-form-left {
    display: none; /* Hide the left section on smaller screens */
  }

  .events-form-right {
    width: 100%;
    padding: 20px;
  }

  .events-form-right h2 {
    font-size: 1.5em; /* Adjust font size */
    margin-bottom: 20px; /* Reduce margin */
  }

  .form-group {
    margin-bottom: 10px; /* Adjust margin */
  }

  .form-group label {
    font-size: 0.8em; /* Decrease label font size */
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.8em; /* Decrease input/textarea font size */
    height: 30px; /* Decrease height */
    min-height: 30px;
  }

  .form-group:last-of-type textarea {
    height: 80px; /* Adjust height */
  }

  .submit-button {
    padding: 10px; /* Maintain padding */
    font-size: 0.9em; /* Adjust button font size */
    margin-top: 15px; /* Adjust margin */
  }
}

@media (max-width: 916px) {
  .events-form-right h2 {
    font-size: 1.4em; /* Further decrease heading size */
    margin-bottom: 15px; /* Reduce margin */
  }

  .submit-button {
    font-size: 0.85em; /* Decrease button font size */
    padding: 8px; /* Adjust padding for better fit */
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.75em; /* Further decrease input/textarea font size */
  }
}