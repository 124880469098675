@import '../../styles/customMediaQueries.css';

.root {
  display: none;

  /* Borders */
  outline: none;
}

@media only screen and (max-width: 767px) {
  .priceSeparator {
    top: 0px;
    padding-top: 5px;
  }
}
.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  padding: 15px 30px 20px 30px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.isOpenAsPopup {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.plain {
  width: 100%;
}

.isOpen {
  display: block;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
  align-items: baseline;
}
@media (--viewportMedium) {
  .contentWrapper {
    padding-top: 0px;
    padding-bottom: 8px;
    margin-top: 0px;
  }
}

.label {
  composes: h5 from global;

  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0 13px 0;
  margin: 0 18px 0 0;
}
@media (--viewportMedium) {
  .label {
    padding: 8px 0 12px 0;
  }
}

.inputsWrapper {
  display: flex;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.minPrice {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 100%;
  text-align: center;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;
  font-family: var(--fontFamilyOpenSansBold);
  font-size: 22px;

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}
.maxPrice {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 80px;
  text-align: center;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;
  font-family: var(--fontFamilyOpenSansBold);
  font-size: 22px;

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.priceSeparator {
  margin: 4px 8px 0 8px;
  font-size: 22px;
  font-family: var(--fontFamilyOpenSansBold);
  position: relative;
  left: 0px;
  top: -3px;
}

@media (--viewportMedium) {
  .priceSeparator {
    margin: 2px 8px 0 8px;
    font-size: 22px;
    font-family: var(--fontFamilyOpenSansBold);
    position: relative;
    left: 0px;
    top: -3px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 17px 0 25px 0;
}
@media (--viewportMedium) {
  .sliderWrapper {
    padding: 16px 0 24px 0;
  }
}

.buttonsWrapper {
  display: flex;
}

.clearButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--successColorLightDark);
  font-weight: 600;

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--successColorLightDark);
    transition: width var(--transitionStyleButton);
  }
}

.priceDailyFIlter {
  display: flex;
  width: 100%;
  border: 1px solid var(--ManageAvailabilityCalendar_blockedColorHover);
  margin-bottom: 15px;
}

.priceDailyFIlter span {
  display: flex;
  width: 100%;
  justify-content: center;
}

.rangeWrap {
  display: flex;
}

.activePriceRadio {
  background: var(--attentionColor);
}

.priceDailyFIlter label {
  padding: 5px 10px;
  width: 100%;
}

.priceDailyFIlter label > span:first-child {
  display: none;
}

.priceDailyFIlter label > span {
  font-weight: 800;
  margin: 0px;
  color: var(--matterColorDarkGray);
}
