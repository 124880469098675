@import '../../styles/customMediaQueries.css';

.root {
}

.floorPlanImage {
  margin-top: 10px;
  margin-bottom: 50px;
  position: relative;
}

.tagsLabel {
  font-size: 16px;
}
.heartIcon {
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  background-color: rgb(26 26 26 / 60%);
  line-height: 0;
  margin-left: 4px;
  /* &:hover {
    background-color: #e3e1e1;
  } */
}

.heartIcon.iconLiked {
  background: var(--marketplaceColorLight);
}

.heartDisabled > svg {
  fill: var(--marketplaceColorLight);
}
.reviewWrap > ul > li > div > div:nth-child(2) > h3 {
  display: none;
}

.heartIcon > svg {
  fill: var(--matterColorLight);
  transition: all 0.2s;
}

@media only screen and (max-width: 767px) {
  .floorPlanImage img {
    position: initial;
  }
  .sectionFeatures > div > div > p {
    font-size: 16px !important;
  }
  .iframeWrap {
    height: 18rem !important;
  }
  .actionBar {
    width: 94% !important;
  }
  .heartIcon > svg {
    height: 23px !important;
    width: 23px !important;
  }
  .locationTitle {
    margin-bottom: 5px !important;
  }
  .vitualWalkTitle {
    margin-bottom: 10px !important;
    background-color: #fcfcfc !important;
  }
  .featuresTitle {
    background-color: #f2f2f245;
  }
  .spacerNameAlign {
    background-color: #fcfcfc !important;
  }
}

.iconLiked > svg {
  fill: var(--matterColorBright);
  transition: all 0.2s;
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.sectionSpaceOwnerConnection {
  position: relative;
  display: flex;
  margin: 25px 0px 20px 0px;
}
.spaceOwnerPhoto {
  height: 70px !important;
  width: 70px !important;
}
.spaceOwnerTitle {
  position: absolute;
  left: 15%;
  top: 35%;
}

.categoryName,
.sqFtNum {
  font-weight: 500;
  color: #1e1d1d;
}

.sectionFeaturesAlign {
  display: grid;
  grid-template-columns: 100%;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 33.33%);
  }
}

.featuresAmenitiesTitle {
  composes: h3 from global;
  color: var(--matterColorDarkGray);
  margin-top: 30px;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
  font-size: 18px;
}
.floorplan__embla__slide img {
  position: static;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .spaceOwnerTitle {
    left: 18%;
  }
  .floorPlan__embla__button__prev {
    left: 0.3rem !important;
  }
  .floorPlan__embla__button__next {
    right: 0.3rem !important;
  }
  .spaceHighlights {
    flex-direction: row !important;
  }
  .featuresTitle {
    margin-bottom: 0px !important;
  }
  .sectionFeaturesAlign {
    margin-bottom: 0px !important;
  }
  .categoryName,
  .sqFtNum {
    margin-left: 5px;
  }
  .avatarMobile1 {
    height: 60px !important;
    width: 60px !important;
    margin-top: 0px !important;
  }

  .map div img {
    height: 450px;
    width: 100%;
    object-fit: cover;
  }

  .map {
    height: 450px !important;
  }

  /* .map div {
    height: 450px !important;
  } */
}
.sectionOperationalHrsAlign {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
}
.socialLinksTags {
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
}
.spacerConnections {
  margin-bottom: 20px;
}
.priceDesc {
  font-size: 16px;
}

.spacerNameAlign {
  flex-direction: row !important;
}

.policiesData {
  margin-top: 0px;
}

.policiesHeading {
  /* margin: 25px 0 0px 0; */
  color: var(--matterColorDarkGray);
  display: flex;
  flex-direction: column;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 0px;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.shareProduct button {
  margin: 0px 3px;
  @media (--viewportMedium) {
    margin: 0px 8px;
  }
}

.shareProduct button svg {
  width: 35px;
  @media (--viewportMedium) {
    width: 40px;
  }
}

.shareProduct {
  position: absolute;
  bottom: -45px;
  right: 10px;
  @media (--viewportMedium) {
    top: 20px;
    right: 30px;
    bottom: unset;
  }
}
.listingBanner {
  position: relative;
  margin-top: 5px;
}
.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}
.hourlyTitle {
  display: flex;
  flex-direction: row;
}
.hourlyPrice {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.hourlyPrice span {
  font-size: 14px;
}
.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 40%);
  color: var(--marketplaceColor);

  z-index: 1; /* bring on top of mobile image */
  border: 1px solid var(--matterColorLight);
  background-color: #4a4a4ac7;

  @media (--viewportMedium) {
    border: 0;
    background-color: var(--matterColorGrayout);
  }

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  composes: h4 from global;
  margin: 0;
  padding: 5px 12px 5px 12px;

  @media (--viewportMedium) {
    padding: 14px 12px 14px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 0;
  padding: 5px 12px 5px 12px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    padding: 14px 12px 14px 24px;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 5px 12px 5px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 14px 24px 14px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    /* max-height: 50vh; */
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 40px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 40px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;
  border: 5px solid var(--matterColorLight);
  margin-top: 20px;
  position: relative;
  left: -10px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
    left: unset;
    margin-top: 0px;
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;
  border: 5px solid var(--matterColorLight);
  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  /* padding-bottom: 8px; */
}

.initialsDesktopForConnections {
  font-size: 30px;
  font-weight: var(--fontWeightBold);
  /* padding-bottom: 8px; */
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 20px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--headingColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;
  font-size: 15px;
  color: var(--tabNavColor);
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: inline;
}
.listingInfoPDP {
  font-weight: 600;
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--successColorLightDark);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}
.sectionDescription .description {
  color: var(--matterColorDarkGray);
}
.sectionDescription {
  padding: 0 20px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 20px;
    padding: 0px;
  }
}

.descriptionTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorDarkGray);

  margin-top: 0;
  margin-bottom: 13px;
  font-size: 18px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  padding: 0 20px;
  margin-bottom: 25px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0px;
    padding: 0px;
    margin-top: 0px;
  }
}

.spaceHighlights {
  margin: 0px 5px 0px 0px;
  border: 1px solid #b2b2b2;
  padding: 14px 8px;
}
.operationlHrs {
  display: flex;
  flex-direction: row;
  /* Font */
  composes: h3 from global;
  color: var(--matterColorGreyDark);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}
.featuresTitle {
  display: flex;
  flex-direction: column;
  /* Font */
  composes: h3 from global;
  color: var(--matterColorDarkGray);

  margin-top: 0;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
  font-size: 16px;
  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 12px;
  }
}
.vitualWalkTitle {
  @media (--viewportMedium) {
    margin-bottom: 10px !important;
    margin-top: 30px !important;
  }
}
.featuresTitle span {
  display: inline-block;
  font-size: 18px;
  font-style: var(--fontFamilyOpenSans);
  letter-spacing: 0.5px;
  margin-bottom: 3px;
}

.categoryLabels > span {
  display: inline-block;
  font-size: 18px;
  font-style: var(--fontFamilyOpenSans);
  letter-spacing: 0.5px;
  margin-bottom: 3px;
}

.categoryName {
  font-size: 16px !important;
}
.sqFtNum {
  font-size: 16px !important;
}

.sectionFeatures > div > div > p {
  font-size: 17px;
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 20px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorDarkGray);

  margin-top: 0;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  font-size: 18px;
  @media (--viewportMedium) {
    margin-top: 38px;
    margin-bottom: 15px;
  }
}

.sectionReviews {
  padding: 0 20px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  composes: h3 from global;
  color: var(--matterColorDarkGray);

  margin: 35px 0 20px 0;
  letter-spacing: 0.5px;
  font-size: 18px;

  @media (--viewportMedium) {
    margin: 35px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 35px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;
  color: var(--matterColorGreyDark);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.iframeDiv {
  height: 19rem;
  position: relative;
}

.embla {
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: calc(1rem * -1);
}

.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  padding-left: 1rem;
  position: relative;
}

.iframeWrap {
  display: block;
  height: 22rem;
  position: relative;
}
.virtualMainImage {
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
}

.embla_thumbs {
  margin-top: 0.8rem;
}

.embla_thumbs__viewport {
  overflow: hidden;
}

.embla_thumbs__container {
  display: flex;
  flex-direction: row;
  margin-left: calc(0.8rem * -1);
}

.embla_thumbs__slide {
  flex: 0 0 50%;
  min-width: 0;
  padding-left: 0.8rem;
  position: relative;

  @media (--viewportMedium) {
    flex: 0 0 25%;
  }
}

.embla_thumbs__slide__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  opacity: 0.2;
  transition: opacity 0.2s;
  position: relative;
}

.embla_thumbs__slide__button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.embla_thumbs__slide_selected .embla_thumbs__slide__button {
  opacity: 1;
}

.venuRulesWrap {
  display: block;
  padding: 0 20px;
  margin-top: 30px;
  @media (--viewportMedium) {
    padding: 0px;
  }
}

.policiesWrap {
  display: block;
  padding: 0 20px;
  @media (--viewportMedium) {
    padding: 0px;
  }
}

.dotSpan {
  font-size: 10px;
  top: -1px;
  position: relative;
}

.dotSpan:last-child {
  margin: 0 2px;
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.ratingWrap {
  display: inline;
  margin-left: 10px;
}

.ratingStarWrap {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: relative;
  top: unset;
  white-space: nowrap;
  left: 5px;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.totalReviewNo {
  display: inline;
  color: var(--successColorLightDark);
  font-weight: 700;
  margin-left: 10px;
  /* font-family: var(--fontFamilyOpenSansExtraBold); */
}

.reviewWrap {
  position: relative;
}

.reviewWrap > ul {
  position: relative;
  max-height: 270px;
  overflow: hidden;
  transition: all 1s;
}

.reviewWrap > ul.active {
  max-height: 100%;
}

/* Floor Plan Carousel */

.floorplan__embla {
  padding: 1.8rem;
  border: 1px solid var(--ManageAvailabilityCalendar_blockedColorHover);
  position: relative;
}
.floorplan__embla__viewport {
  overflow: hidden;
}
.floorplan__embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  flex-direction: row;
  height: auto;
  margin-left: calc(1rem * -1);
}
.floorplan__embla__slide {
  overflow: hidden;
  flex: 0 0 100%;
  min-width: 0;
  padding-left: 0;
  position: relative;
}
.floorplan__embla__slide > img {
  height: 100%;
  max-height: 100%;
}

.floorplan__embla__dot,
.floorplan__embla__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}
.floorplan__embla__dots {
  z-index: 1;
  bottom: -1.5rem;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.floorplan__embla__dot {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  margin-right: 0.9rem;
  margin-left: 0.9rem;
}
.floorplan__embla__dot:after {
  background: var(--ManageAvailabilityCalendar_blockedColorHover);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  content: '';
}
.floorplan__embla__dot__selected:after {
  background: var(--matterColorDarkGray);
}
.floorplan__embla__button {
  z-index: 1;
  color: #000;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}
.floorPlan__embla__button__prev {
  left: 0rem;
}
.floorPlan__embla__button__next {
  right: 0rem;
}
.floorplan__embla__button:disabled {
  opacity: 0;
}
.floorplan__embla__button__svg {
  width: 65%;
  height: 65%;
}

/* Floor plan carousel ends */

.walkthroughCarousel {
  position: relative;
}

.walkthroughCarousel .floorplan__embla {
  /* padding: 0px;
  border: none; */
}

.walkthroughCarousel .floorplan__embla__button {
  color: #000;
}

.virtualButton {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #808080;
  background: #fff;
  padding: 7px 11px;
  font-weight: 700;
  font-size: 15px;
  text-decoration: none;
}

.virtualButton:hover {
  text-decoration: none;
}

.virtualButton img {
  width: 30px;
  margin-right: 5px;
}

.aspectWrapperOverride {
  padding-bottom: 0;
}
.imagesWrap {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 5px;
  row-gap: 5px;
  max-height: 22vh;
  @media (--viewportMedium) {
    max-height: 33vh;
  }
  @media (--viewportLargeIpad) {
    max-height: 50vh;
  }
  & .eachImage {
    position: relative;
    overflow: hidden;
    &::before {
      display: block;
      content: '';
      padding-bottom: 100%;
    }
    @media (--viewportMedium) {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--matterColorAlmostDark);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
        transition: 0.1s opacity linear, 0.1s visibility linear;
      }
      &:hover::after {
        opacity: 0.3;
        visibility: visible;
      }
    }

    & img {
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.grid_for_1 {
  & .eachImage_1 {
    grid-column: 1 / span 6;
    grid-row: 1 / span 2;
  }
}

.grid_for_2 {
  & .eachImage_1 {
    grid-column: 1 / span 3;
    grid-row: 1 / span 2;
  }
  & .eachImage_2 {
    grid-column: 4 / span 3;
    grid-row: 1 / span 2;
  }
}

.grid_for_3 {
  & .eachImage_1 {
    grid-column: 1 / span 4;
    grid-row: 1 / span 2;
  }
  & .eachImage_2 {
    grid-column: 5 / span 2;
    grid-row: 1 / span 1;
  }
  & .eachImage_3 {
    grid-column: 5 / span 2;
    grid-row: 2 / span 1;
  }
}

.grid_for_fourorMore {
  & .eachImage_1 {
    grid-column: 1 / span 4;
    grid-row: 1 / span 2;
  }
  & .eachImage_2 {
    grid-column: 5 / span 2;
    grid-row: 1 / span 1;
    margin-right: -5px; /**as per the column-gap value; */
  }
  & .eachImage_3 {
    grid-column: 5 / span 1;
    grid-row: 2 / span 1;
  }
  & .eachImage_4 {
    grid-column: 6 / span 2;
    grid-row: 2 / span 1;
  }
}

.listingPageBannerSingleImage {
  height: 100%;
  max-height: 100%;
  &:hover {
    transform: none;
    box-shadow: none;
  }
}
