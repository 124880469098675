@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);
  padding-left: 15px;
}

.filtersWrapper {
}

.footer {
  display: flex;
  margin-top: 20px;
  justify-content: right;
}

.filterButton {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}
.resetAllButton {
  composes: filterButton;
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.filtersWrapper > div:nth-child(1) > div > button::after {
  transform: rotate(140deg);
  /* transform: rotate(45deg); */
  /* display: none; */
}

.cancelButton {
  composes: filterButton;
  border: 1px solid var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorGreyDark);
  margin: 0 24px 0 24px;
  outline: none;
  padding: 10px 15px;
  font-weight: 600;

  /* Layout */
  margin: 0 40px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColorGreyDark);
    text-decoration: none;
  }
}

.applyButton {
  composes: filterButton;
  color: var(--matterColor);
  font-weight: 600;
  margin: 0;
  outline: none;
  background: var(--marketplaceColor);
  border: 1px solid var(--ManageAvailabilityCalendar_blockedColorHover);
  padding: 10px 15px;

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
    text-decoration: none;
  }
}
