.root {
    position: relative;
    padding-top: 24px;
    padding-bottom: 17px;
    border-bottom: 1px solid var(--matterColorNegative);
  }
  
  .filterLabel,
  .filterLabelSelected {
    composes: h3 from global;
  
    /* Baseline adjustment for label text */
    margin-top: 0;
    margin-bottom: 12px;
    padding: 4px 0 2px 0;
  }
  
  .filterLabel {
    color: var(--matterColorDark);
  }
  
  .filterLabelSelected {
    color: var(--matterColorDark);
  }
  
  .labelButton {
    /* Override button styles */
    outline: none;
    text-align: left;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .formWrapper {
    padding-left: 20px;
  }
  
  .clearButton {
    composes: h5 from global;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorAnti);
  
    /* Layout */
    display: inline;
    float: right;
    margin-top: 6px;
    padding: 0;
  
    /* Override button styles */
    outline: none;
    text-align: left;
    border: none;
  
    &:focus,
    &:hover {
      color: var(--matterColor);
    }
  }
  