@import '../../styles/customMediaQueries.css';

.favHostName {
  font-size: 14px;
  color: var(--matterColor);
}

.heartIcon {
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  background-color: rgba(26, 26, 26, 0.6);
  line-height: 0;
  margin-left: 4px;
}

.heartDisabled > svg {
  fill: var(--marketplaceColorLight);
}

.heartIcon > svg {
  fill: var(--matterColorLight);
  transition: all 0.2s;
}

.iconLiked > svg {
  fill: var(--marketplaceColorLight);
  transition: all 0.2s;
}

.listingCardDetails {
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.favOnPlp {
  position: absolute;
  margin-right: 0;
  top: 10px;
  right: 10px;
  @media (--viewportMedium) {
    margin-right: 24px;
    top: 15px;
    right: 15px;
  }
}

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border: 1px solid var(--ManageAvailabilityCalendar_blockedColorHover);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

/* .favOnPlp {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 1;
} */

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column-reverse;
  padding: 16px 16px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 20px;
  color: var(--matterColorDark);
  margin-top: 10px;

  @media (--viewportLargeWithPaddings) {
    margin-right: 30px;
  }
}

.priceValue {
  /* Font */
  composes: h2 from global;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-family: var(--fontFamilyOpenSans);
  font-weight: 700;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    letter-spacing: -1px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 18px;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.startKey{
    /* Font */
    composes: marketplaceTinyFontStyles from global;
    color: var(--matterColor);
  
    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;
    font-style: italic;
  
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);
  font-family: var(--fontFamilyOpenSans);
  font-weight: 700;
  letter-spacing: -0.5px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* margin-top: 4px; */
    margin-bottom: 0;

    font-size: 17px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 19px;
  }
}

.certificateInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.userNameTitle {
  display: inline-block;
  position: relative;
  top: 5px;
}

.title {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.longWord {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mainInfo {
  width: 100%;
}

.longWord {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .title {
    width: 86%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .info {
    align-self: center;
    padding: 10px 16px;
    width: 100%;
  }
  .userNameTitle {
    top: 0;
    width: 100%;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .price {
    margin-top: 3px;
    margin-right: 30px;
    background-color: transparent;
  }
  .mainInfo {
    width: 60%;
  }
  .perUnit {
    width: 100%;
  }
  .perUnit span {
    display: inline-block;
    position: relative;
    width: 90%;
    padding-bottom: 4px;
  }
}

.blurCompare {
  opacity: 0.3;
}

.unBlur {
  opacity: 1;
}
.compareIcon {
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  line-height: 0;
  margin-left: 6px;
  background-color: rgba(26, 26, 26, 0.6);
}

.addedToCompare {
  background-color: var(--successColorLightDark);
}
