/* Form Container Styles */
.form-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.image-section {
  width: 50%;
  position: relative;
  overflow: hidden;
}

.contact-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1.0);
}

.image-title {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

.image-subtitle {
  font-size: 18px;
  margin-top: 10px;
}

.form-section {
  width: 50%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.form-heading {
  font-family: 'Helvetica Neue', sans-serif;
  color: black;
  margin-bottom: 20px;
  font-size: 26px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.label {
  font-family: 'Helvetica Neue', sans-serif;
  margin-top: 10px;
  font-size: 16px;
  color: black;
}

.input {
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 14px;
  transition: 0.3s;
  outline: none;
  background-color: #f9f9f9;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.textarea {
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 14px;
  transition: 0.3s;
  outline: none;
  background-color: #f9f9f9;
  min-height: 40px;
  resize: vertical;
  width: 95%; /* Ensure full width on smaller screens */
}

.submit-button {
  margin-top: 20px;
  padding: 15px;
  background-color: #FDD301;
  border: none;
  color: black;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%; /* Ensure button takes full width */
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Media Queries */
@media (max-width: 900px) {
  .form-container {
    flex-direction: column; /* Stack the image and form sections */
  }

  .image-section {
    display: none; /* Hide the image section on smaller screens */
  }

  .form-section {
    width: 100%; /* Full width for form section */
    padding: 20px; /* Reduced padding */
  }

  .image-title {
    font-size: 24px; /* Adjust font sizes */
  }

  .image-subtitle {
    font-size: 16px;
  }

  .form-heading {
    font-size: 22px;
  }

  .input, .textarea {
    font-size: 14px;
  }

  .submit-button {
    font-size: 16px;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .form-container {
    width: 100%;
    padding: 10px; /* Add padding for smaller screens */
  }

  .image-title {
    font-size: 20px;
  }

  .image-subtitle {
    font-size: 14px;
  }

  .form-heading {
    font-size: 20px;
  }

  .input, .textarea {
    font-size: 13px;
  }

  .submit-button {
    font-size: 14px;
    padding: 10px; /* Adjust padding for smaller buttons */
  }
}