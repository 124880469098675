@import '../../styles/customMediaQueries.css';

.root {
}

/* Shared styles for infoText variants */
.infoText {
  composes: h4 from global;

  display: inline-block;
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: top 7px left;

  margin-top: 11px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 14px;
    margin-bottom: 1px;
  }
}
.contactDetailsSection {
  margin-top: 16px;
  margin-bottom: 46px;
  padding-top: 6px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
    padding-top: 0;
  }
}

.phone {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.emailVerified {
  composes: infoText;
  color: var(--successColor);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');
}

.emailUnverified {
  composes: infoText;
  color: var(--matterColorAnti);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.pendingEmailUnverified {
  composes: infoText;
  color: var(--failColor);
  width: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.checkInbox {
  color: var(--matterColor);
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.helperLink {
  color: var(--matterColor);
  border-bottom: solid 1px var(--matterColor);
  line-height: 20px;
  margin: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.tooMany {
  color: var(--matterColorAnti);
}

.emailSent {
  color: var(--matterColor);
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.bottomWrapper {
  margin-top: 46px;

  @media (--viewportMedium) {
    margin-top: 96px;
  }
}

.error {
  composes: h4 from global;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.emailField,
.subjectWrap,
.messageField {
  margin-top: 20px;
  & label {
    margin-bottom: 8px;
    color: var(--matterColorLight);
  }
  & input {
    background: var(--matterColorLight);
    padding: 4px 10px;
    font-size: 15px;
  }

  @media (--viewportLargeIpad) {
    & input {
      font-size: 16px;
    }
  }
}

.subjectWrap {
  width: 100%;
  position: relative;
  & label {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: 20px;
      bottom: -150%;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 8.5px 0 8.5px 12px;
      border-color: transparent transparent transparent #000;
      transform: rotate(90deg) translate(50%, 0%);
      z-index: 9;
    }
  }
  & select {
    background: var(--matterColorLight);
    border-radius: 30px;
    padding: 4px 10px;
    position: relative;
    font-size: 15px;
  }

  @media (--viewportMedium) {
    & label{
      &::after {
        bottom: -130%;
      }
    }
  }

  @media (--viewportLargeIpad) {
    width: 77%;
    & select {
      padding: 4px 20px;
      font-size: 16px;
    }
  }

  @media (--viewportLargeWithPaddings) {
    width: 60%;
  }
}

.emailField {
  & input:disabled {
    background: #ccc;
    border-bottom-color: #7e7b7b;
    cursor: not-allowed;
    padding: 5px 10px;
    margin-top: 5px;
  }
}

.contactUsWrap {
  margin-top: 0px;
}

.messageField textarea {
  line-height: 1.45;
  min-height: 120px;
  background: var(--matterColorLight);
  font-size: 15px;
  padding: 4px 10px;
  width: 100%;
  max-width: -webkit-fill-available;
  @media (--viewportMedium) {
    font-size: 16px;
    padding: 4px 20px;
  }
}

.submitButton {
  margin-top: 40px;
}

.submitButton button {
  width: 100%;
  font-weight: 700;
  line-height: 45px;
  min-height: 40px;
  padding: 0 50px 0 20px;
  position: relative;
  text-align: left;
  max-width: 170px;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 8.5px 0 8.5px 12px;
    border-color: transparent transparent transparent #000;
    transform: rotate(0deg) translateY(-50%);
    z-index: 9;
  }
}

.validation {
  font-size: 14px;
  line-height: 17px;
}

.submitButton button,
.submitButton button:hover,
.submitButton button:focus {
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorDark);
}

.submitButton button:disabled:hover,
.submitButton button:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorDarkGray);
}

.characterWrap {
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 15px;
}

.mailWrap {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-column-gap: 20px;
  & > div {
    width: 100%;
  }

  @media (--viewportLargeIpad) {
    grid-template-columns: repeat(2, 48.4%);
  }
}

.captacha{
  margin: 30px auto;
}
