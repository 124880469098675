@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}
.dailyPriceInput {
  flex-shrink: 0;
  width: 100%;
  padding-top: 20px;
}

.cleaningFeeInput {
  flex-shrink: 0;
  width: 100%;
  padding-top: 20px;
  display: none;
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  margin-top: 20px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 50px;
  }
}

.previousButton {
  width: 241px;

  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorDarkGray);
  margin-right: 0px;
  margin-bottom: 0px;
  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 24px;
    margin-right: 15px;
  }
}

.previousButton:hover,
.previousButton:focus {
  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorDarkGray);
  box-shadow: none;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0px 30px;
  @media (--viewportLarge) {
    margin: 0px;
  }
}

.buttonWrapper > button {
  width: 50%;
  font-size: 14px;
  margin-right: 10px;
  @media (--viewportLarge) {
    width: 269px;
    font-size: 16px;
  }
}

.buttonWrapper > button:last-child {
  width: 50%;
  margin-right: 0px;
  @media (--viewportLarge) {
    width: 269px;
  }
}

.warningPriceMsg {
  color: var(--successColor);
  margin: 0px;
  font-size: 15px;
  font-weight: 700;
}

.priceWrapDiv{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-top: 1px solid var(--ManageAvailabilityCalendar_blockedColorHover);
}

.dynamicPriceWrap{
  max-width: 500px;
}

.weedDaysWrap{
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
  & div:first-child{
    margin-top: 15px;
  }
  & > div{
    max-width: 540px;
    width: 100%;
  }
  & button{
    margin: 10px 7px 0px;
    border: 1px solid var(--matterColorNegative);
    color: #000;
    padding: 4px 11px;
    border-radius: 10px;
    width: 60px;
    background: var(--matterColorNegative);
    position: relative;
    &:hover{
      text-decoration: none;
    }
  }
  & button.activeWeekBtn{
    color: var(--matterColor);
    background: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
  }
}

.weekDaysName{
  font-weight: bold;
  font-size: 15px;
}

.HourlyPrice, .DailyPrice{
  display: block;
  & label{
    font-size: 14px;
  }
  & input{
    line-height: normal;
    margin-top: 5px;
    width: 120px;
  }
  & div:last-child{
    width: 120px;
    font-size: 14px;
  }
}

.fieldArrayRemove{
  & svg{
    stroke: var(--ManageAvailabilityCalendar_blockedColorHover);
    fill: var(--ManageAvailabilityCalendar_blockedColorHover);
  }
}

.dynamicBox{
  margin-top: 15px;
  margin-bottom: 0px;
}

.snippetMsg{
  font-size: 13px;
  line-height: 22px;
  font-weight: 600;
  color: var(--matterColorDarkGray);
  margin-bottom: 20px;

}