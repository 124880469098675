@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.submitButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
  &:disabled {
    color: var(--matterColorDark);
    background-color: var(--attentionColor);
  }
  &:not(:disabled),
  &:not(:disabled):hover {
    color: var(--matterColorDark);
    background-color: var(--attentionColor);
  }
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}
