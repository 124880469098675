@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  & label,
  & input {
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.input {
  margin-bottom: 20px;
  & label,
  & input {
    color: var(--matterColorLight);
  }

  & input {
    border-bottom-color: var(--attentionColor);
    padding-bottom: 8px;
    @media only screen and (max-width: 767px) {
      font-size: 15px;
    }
    & + div {
      font-size: 12px;
      line-height: 1.25;
    }
  }

  & label {
    font-size: 16px;
    margin-bottom: 3px;
  }
  & input::placeholder {
    color: #e6e6e696;
  }
  & input:-internal-autofill-selected {
    background-color: transparent !important;
  }

  & input:hover {
    border-bottom-color: var(--attentionColor);
  }
}

.name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
  @media (--viewportSmall) {
    flex-direction: row;
  }

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: 100%;
  @media (--viewportSmall) {
    width: calc(45% - 9px);
  }
}

.lastNameRoot {
  width: 100%;
  @media (--viewportSmall) {
    width: calc(55% - 9px);
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  /* composes: marketplaceModalBottomWrapper from global; */
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  line-height: 1;
  margin: 40px 0 30px;
  text-align: center;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  color: var(--matterColorLight);
  font-size: 12px;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: var(--matterColorLight);
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.submitBtn {
  &:disabled {
    color: var(--matterColorDark);
    background-color: var(--attentionColor);
  }
  &:not(:disabled),
  &:not(:disabled):hover {
    color: var(--matterColorDark);
    background-color: var(--attentionColor);
  }
}

.validateMsg {
  font-size: 12px;
  line-height: 1.25;
}
.termsServicesLink {
  color: var(--matterColorLight);
}

.captacha{
  margin: 30px auto;
}

.checkbox{
  display: inline-block;
  padding: 2px 0 3px 0;

  & svg{
    background: #fff;
  }

  & span:first-child{
    margin-right: 5px;
    position: relative;
    top: 3px;
  }

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}