@import '../../styles/customMediaQueries.css';

.compareWrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: unset;
  justify-content: normal;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  max-height: 100px;
  overflow: scroll;
  scrollbar-color: #000000 #000000;
  scrollbar-width: thin;
  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    overflow: hidden;
    max-height: auto;
    justify-content: center;
    align-items: center;
  }
}


.products {
  border: 1px solid #000;
  padding: 10px;
  display: grid;
  grid-template-columns: 35% 65%;
  background: rgb(255 255 255 / 90%);
  align-items: flex-start;
  flex: 0 0 170px;
  & p {
    margin: 0px;
  }
  @media (--viewportMedium) {
    grid-template-columns: 40% 60%;
    flex: unset;
  }
}

.listingDetail {
  display: flex;
  flex-direction: column;
  margin: 0px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  & p {
    margin: 0px;
    text-transform: capitalize;
    font-weight: bold;
    line-height: normal;
    font-size: 14px;
  }
}

.listingImage {
  p{
    width: 100%;
    height: 100%;
  }

  img{
    height: 70px;
    object-fit: cover;
    height: 100%;
  }
  @media (--viewportMedium) {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.mainWrapper {
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 10px 0px;

  position: fixed;
  bottom: 0;
  /* display: none; */
  width: 100%;
  @media (--viewportMedium) {
    width: 100%;
  }
  @media (--viewportLarge) {
    width: 62.01%;
  }
}

.extendCSSWidth {
  width: 100%;
  @media (--viewportMedium) {
    width: 100%;
  }
  @media (--viewportLarge) {
    width: 68.1%;
  }
}

.selectBtn {
  background-color: var(--marketplaceColor);
  padding: 8px 15px;
  border-radius: 5px;
  line-height: normal;
  cursor: pointer;
  & a {
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }
}

.clearBtn {
  background: transparent;
  padding: 7px 15px;
  border-radius: 5px;
  line-height: normal;
  color: var(--matterColorBright);
  border: 1px solid var(--matterColorBright);
  cursor: pointer;
  &:hover {
    color: var(--matterColorBright);
    background: transparent;
  }
}

.compareBtnWrap {
  display: flex;
  margin-bottom: 10px;
  gap: 20px;
  align-items: center;
}

.scrollMsg{
  color: #fff;
  display: block;
  margin-top: 0px;
  font-size: 15px;
  @media (--viewportMedium) {
    display: none;
  }
}

.wrapperSection{
  display: flex;
  flex-direction: column;
}