@import '../../styles/customMediaQueries.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.features  ul > li > span > label > span:last-child {
  font-size: 15px;
  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 20px;
  }
}

.otherRules{
  display: flex;
  flex-direction: column;
}

.otherRules > textarea{
  display: block;
  border: 1px solid var(--matterColorAnti);
  height: 96px !important;
  padding: 10px;
  margin-top: 10px;
  overflow: hidden !important;
  width: auto !important;
}

.previousButton{
  width: 241px;
 
  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorDarkGray);
  margin-right: 0px;
  margin-bottom: 24px;
  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 24px;
    margin-right: 15px;
  }
}

.previousButton:hover, .previousButton:focus{
  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorDarkGray);
  box-shadow: none;
}

.buttonWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0px 30px;
  @media (--viewportLarge) {
    margin: 0px;
  }
}

.buttonWrapper > button{
  width: 53%;
  font-size: 14px;
  margin-right: 10px;
  @media (--viewportLarge) {
    width: 269px;
    font-size: 16px;
    margin-top: 45px;
  }
}

.buttonWrapper > button:last-child{
  width: 47%;
  margin-right: 0px;
  @media (--viewportLarge) {
    width: 269px;
  }
}