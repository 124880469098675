@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 20px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.serviceWrap {
  margin-top: 25px;
}
.serviceWrap ul {
  margin-top: 10px;
}
.generalWrap ul {
  margin-top: 10px;
}

.features  ul > li > span > label > span:last-child,
.generalWrap  ul > li > span > label > span:last-child,
.serviceWrap  ul > li > span > label > span:last-child {
  font-size: 15px;
  @media (--viewportMedium) {
    font-size: 16px;
  }
}
.accordin > div {
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 10px 10px 10px;
  /* border-top: 1px solid #b9b9b9; */
  border-bottom: 1px solid #b9b9b9;
  margin-top: 0px;
}

.accordin > div > span {
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  display: block;
  position: relative;
}

.accordin > div > span::after {
  content: '';
  position: absolute;
  right: 0;
  top: 20%;
  border: solid #757575;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: all 0.3s;
}

.accordin > div > span[aria-expanded='true']::after {
  -webkit-transform: rotate(140deg);
  transform: rotate(140deg);
}

.previousButton {
  width: 241px;

  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorDarkGray);
  margin-right: 0px;
  margin-bottom: 24px;
  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 24px;
    margin-right: 15px;
  }
}

.previousButton:hover,
.previousButton:focus {
  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorDarkGray);
  box-shadow: none;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0px 30px;
  @media (--viewportLarge) {
    margin: 0px;
  }
}

.buttonWrapper > button {
  width: 50%;
  font-size: 14px;
  margin-right: 10px;
  @media (--viewportLarge) {
    width: 269px;
    font-size: 16px;
    margin-top: 35px;
  }
}

.buttonWrapper > button:last-child {
  width: 50%;
  margin-right: 0px;
  @media (--viewportLarge) {
    width: 269px;
  }
}
