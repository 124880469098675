@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.checkIcon {
}

.venueRulesIcon {
  margin-bottom: 4px;
}
.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--matterColorDark);
}

.eventList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  border: 1px solid #b2b2b2;
  padding: 15px 15px;
  /* font-family: sans-serif; */
  margin: 5px 5px 5px 0px;

  @media (--viewportMedium) {
    margin: 5px 5px 5px 0px;
  }
}

.eventWrapper {
  display: grid;
  align-items: stretch;
  justify-content: center;
  grid-template-columns: repeat(3, 33.33%);
  margin-top: -5px;
  /* grid-gap: 21px; */
  @media (--viewportMedium) {
    align-items: stretch;
  }
}

.eventList img {
  width: 30%;
  margin: auto;
  height: auto;
}

@media only screen and (max-width: 767px) {
  .eventList img {
    width: 50%;
    padding: 5px;
  }
  .eventList {
    padding: 0px;
    background-color: #f2f2f245;
  }
  .eventList p {
    margin-bottom: 10px !important;
    text-align: center;
    font-size: 13px;
  }
  .eventWrapper {
    margin-top: 5px;
  }
}

.eventList p {
  margin: 10px 0px 0px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  font-family: var(--fontFamilyOpenSans);
  text-align: center;
  font-size: 18px;
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
}

.iconWrapper img{
  width: 15px;
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  composes: marketplaceBodyFontStyles from global;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: 500;
  color: #1e1d1d;
}

.notSelectedLabel {
  color: var(--matterColorNegative);
  position: relative;

  /* line-through */
  background-image: linear-gradient(
    transparent 10px,
    var(--matterColorNegative) 10px,
    var(--matterColorNegative) 11px,
    transparent 11px
  );

  @media (--viewportMedium) {
    background-image: linear-gradient(
      transparent 12px,
      var(--matterColorNegative) 12px,
      var(--matterColorNegative) 13px,
      transparent 13px
    );
  }
}
