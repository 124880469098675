@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  background-color: var(--matterColorDark);

  @media (--viewportLarge) {
    padding: 0 0 0px 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 50px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--marketplaceColor);
  }
}

.listItem a span {
  font-size: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--contentMaxWidth);
  padding: 20px 16px;

  @media (--viewportMedium) {
    margin: 0 auto;
    /* padding: 20px 32px; */
  }
}

/* Mobile only social media links */

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */

.footerLinkMain {
  color: var(--matterColorLight);
  font-weight: 900;
  font-family: 'OpenSans-Bold';
  font-size: 22px;
  margin-bottom: 30px;
}
.links {
  /* Layout */
  display: grid;
  grid-template-columns: repeat(auto, 2);
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    grid-template-columns: 25% 75%;
  }

  @media (--viewportLargeIpad) {
    /* grid-template-columns: 200px 1fr; */
    grid-template-columns: 25% 75%;
  }
}
.copyRight {
  background-color: var(--matterColorDark);
}

.copyRight h4 {
  color: white;
  font-size: 14px;
  padding: 20px 16px;
  margin: 0px auto;
  max-width: var(--contentMaxWidth);
}
/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  margin-bottom: 24px;
  max-width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportMedium) {
    order: 1;
    margin-right: 8px;
  }
  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 20px;
    margin-top: 20px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
  @media (--viewportLargeWithPaddings) {
    margin-top: -20px;
    margin-left: 0px;
    margin-bottom: 10px;
  }
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  margin-left: 0px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.organizationInfo {
  color: var(--matterColorLight);
  /* text-align: left; */
  display: flex;
  justify-content: center;
}

.organizationDescription {
  composes: marketplaceTinyFontStyles from global;
}

.connectUs{
  color: var(--matterColorLight);
  font-weight: 900;
  font-family: 'OpenSans-Bold';
  font-size: 22px;
  margin-bottom: 30px;
  margin-top: 0px;
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    flex: 0 1 50%;
    margin-bottom: 10px;
  }

  @media (--viewportMedium) {
    & > div {
      margin-bottom: 0;
    }
    grid-template-columns: repeat(4, auto);
    justify-content: space-around;
    margin: 0 0 20px 30px;
    flex-wrap: nowrap;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  margin-bottom: 18px;
  line-height: 1;
}

.link {
  /* Font */
  composes: h5 from global;
  line-height: 20px;
  font-family: 'OpenSans-SemiBold';
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */

/* Desktop and Mobile: searches - second column */
/* This is not always visible */

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.lightTheme {
  background-color: var(--matterColorLight);
  & .link,
  & .link:hover,
  & .footerLinkMain,
  & .organizationInfo {
    color: var(--matterColorDark);
  }
}
.social_icons_btn {
  display: flex;
}
.icons {
  width: 45px;
  height: 45px;
  font-size: 2rem;
  font-weight: 500;
  text-decoration: none;
  background: #111;
  margin: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* box-shadow: 0 2px 2px #d1d1d1; */
  color: #fff;
  cursor: pointer;
  transition: all 0.15s ease;
}
.facebook:hover {
  background: #4267b2;
}
.instagram:hover {
  background-image: linear-gradient(#8a3ab9, #e95950, #bc2a8d, #fccc63);
}
