@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textarea {
  composes: h4 from global;
  border-bottom-width: 0;

  /* Avoid text going behind the submit button */
  padding: 22px 52px 25px 0;

  margin: 0;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: calc(100% - 52px);

  border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);
  background-color: transparent;

  @media (--viewportMedium) {
    margin: 0;
  }
  @media (--viewportLarge) {
    padding: 0 0 5px 0;
    margin: 0;
    width: 100%;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  display: flex;
  flex-direction: row;
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  composes: h5 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  composes: button buttonText buttonBordersSecondary buttonColorsSecondary from global;

  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: inline-block;
  margin: 17px 0 0 0;
  width: auto;
}

.activeAttach .attachmentWrap {
  display: none;
}

.attachmentWrap {
  display: flex;
  margin-top: 5px;
  margin-right: 15px;
  width: 100%;

  grid-column: 1 / 2;
  @media (--viewportMedium) {
    grid-column: unset;
  }
}

.uploadClearBtn {
  border-width: 1px;
  border-style: solid;
  border-color: var(--matterColorNegative);
  border-radius: 7px;
  border: none;
  padding: 0px;
  line-height: 0;
  cursor: pointer;
  margin-left: 10px;
}

/* .uploadClearBtn:hover{
    border-color: var(--matterColorAnti);
    outline: none;
    box-shadow: var(--boxShadowButton);
} */

.fileNameCloseWrap {
  margin: 0;
  display: flex;
  width: 60%;
  align-items: baseline;
}

.fileNameCloseWrap span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 190px;
}


.activeAttach .fileNameActive{
  padding: 7px 10px;
  @media (--viewportMedium) {
    width: 40%;
    padding: 0 10px;
  }
  @media (--viewportLargeIpad) {
    width: 60%;
  }
}

.activeAttach + div{
  position: relative;
  left: -20px;
  @media (--viewportMedium) {
    left: 0px;
  }
}

.fileNameActive {
  border-width: 1px;
  border-style: solid;
  border-color: var(--matterColorNegative);
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  width: 60%;
  grid-column: 1 / 3;
  @media (--viewportLargeWithPaddings) {
    grid-column: unset;
  }
}

.fileNameCloseWrap button {
  display: none;
}

.fileNameActive button {
  display: block;
}

.fileErMsg {
  display: block;
  grid-column: 1 / 3;
  width: 111%;
  margin-bottom: 10px;
  grid-row: 1;
  color: var(--failColor);
  @media (--viewportMedium) {
    width: 100%;
    grid-row: unset;
  }
}

.activeAttach {
  position: relative;
  left: 0px;

  @media (--viewportMedium) {
    left: 30px;
  }
}

.attactmentBtnWrap {
  display: grid;
  width: 100%;
  justify-content: unset;
  grid-column: 1 / 3;
  grid-row: 3;
  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    width: 113%;
    z-index: 1;
    grid-row: 2;
  }

  @media (--viewportLargeIpad) {
    width: 100%;
  }
}

.quoteWrap{
  display: flex;
  align-items: center;
  grid-column: 1 /2;
  grid-row: 2;
  & p{
    margin: 0px;
    margin-right: 10px;
  }
  @media (--viewportMedium) {
    grid-row: 2;
  }
}