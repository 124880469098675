@import '../../styles/customMediaQueries.css';

.root {
  /* Font */
  composes: h6 from global;
  color: var(--matterColorLight);
  text-align: center;

  background-color: var(--failColor);

  /* Layout of a red dot */
  display: inline-block;
  width: auto;
  height: 22px;
  margin-left: 4px;
  min-width: 22px;
  /* border: none; */
  line-height: inherit;
  border-radius: 50%;

  /* Font margins off */
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;

    top: -12px;
    position: relative;
  }
}
@media only screen and (max-width: 767px) {
  .root {
    position: relative;
    bottom: 10px;
  }
}
