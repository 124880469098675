@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}
.bioInfoMob {
  display: none;
}
.impField {
  color: red;
}
@media only screen and (max-width: 767px) {
  .tipWrap {
    position: relative;
    margin-top: -16px;
    left: 12px;
  }
  .root {
    margin-top: 0px;
  }
  .userProfileAddress {
    margin-top: 25px;
  }

  .tipWrap > .tip {
    margin-top: 10px !important;
  }
  .tipWrap > .fileInfo {
    top: 50px;
  }
  .skeletonWrap {
    display: grid !important;
    grid-template-columns: 30% 70% !important;
  }

  .avatarContainer,
  .avatarPlaceholder {
    height: 115px !important;
    width: 115px !important;
  }
  .bioInfoMob {
    display: block;
    color: var(--matterColorAnti);
    margin-top: 11px;
  }
  .bioInfo {
    display: none;
  }
  .bioNameHideMobile label {
    display: none;
  }
  .yourProfilePicture {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0px !important;
  }
  .yourProfilePicture h3 {
    display: flex;
    margin-top: 25px;
  }
  .uploadAvatarWrapper label {
    width: 100%;
  }
  .profileWrap {
    display: grid !important;
    grid-template-columns: 30% 70% !important;
  }
  .changeAvatar {
    bottom: unset !important;
    right: 0px !important;
    left: 25px !important;
    top: -30px;
  }
  .changeBtnWrap {
    position: relative;
    margin-top: -16px;
    left: 12px;
  }
  .tip {
    position: absolute;
    top: 50%;
    left: 25px;
    line-height: 18px;
    margin-top: 20px !important;
    font-size: 13px !important;
  }
  .fileInfo span {
    font-size: 12px !important;
  }
  .fileInfo {
    position: absolute;
    top: 65px;
    left: 20px;
  }
  .avatarContainer,
  .avatarPlaceholder {
    top: 20px;
    left: 2px;
  }
  .profileBtnStyle {
    width: 60%;
    margin: auto;
  }
  .lastSection {
    margin-bottom: 20px !important;
  }
  .nameContainer {
    display: flex;
    flex-direction: column;
  }
  .nameContainer div {
    width: 100%;
    margin-top: 20px;
  }
  .sectionContainer .sectionTitle {
    font-size: 16px;
  }
  .profileImgMob {
    font-size: 12px !important;
  }
}
.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.sectionContainer {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 56px;
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.profileUserImg {
  margin-bottom: 0px !important;
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 60px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  @media (--viewportMedium) {
    /* width: var(--ProfileSettingsForm_avatarSizeDesktop); */
    width: 100%;
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: 50%;
  cursor: pointer;

  @media (--viewportMedium) {
    /* width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop); */
    width: 245px;
    height: 245px;
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

/* .avatarContainer{
  width: 220px;
  height: 220px;
} */

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.userProfileDiv {
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
  position: relative;
}

.userImgAddTip {
  margin-top: 44px;
  margin-bottom: 20px;
  position: absolute;
  left: 50%;
  width: 100%;
  top: 46%;
}
.changeAvatar {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 27px;
  right: -129px;
  /* Dimensions */
  width: fit-content;
  height: 41px;
  padding: 11px 10px 7px 35px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    /* bottom: -10px; */
    position: relative;
    bottom: unset;
    margin-left: 40px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 11px 10px 7px 35px;
    margin-right: -20px;
    top: 30%;
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
  }
}

.profileWrap {
  display: flex;
  align-items: center;
}

.skeletonWrap {
  display: flex;
  align-items: center;
}

.changeBtnWrap {
  display: block;
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  composes: marketplaceDefaultFontStyles from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 15px;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 40px;
  }
}

.fileInfo {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-left: 40px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;
}
