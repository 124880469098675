@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  background-color: var(--matterColorDark);

  @media (--viewportLarge) {
    padding: 0 0 0px 0;
    border-top: none;
  }
}

.message, .captchamessage{
    padding: 10px;
    display: flex;
    align-items: center;
    min-height: 55px;
    background-color: #ddffdd;
    border-radius: 5px;
    
    & p {
      margin: 0;
      line-height: 1.45;
      font-weight: bold;
      color: #000 !important;
    }
  }

  .captchamessage{
    background-color: #fae8e8;
  }