@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.neighbourhoodOptions option {
  color: var(--matterColor);
}
.neighbourhoodOptions {
  color: var(--matterColorDark);
  font-weight: 600;
}
.neighbourhoodPlaceholder {
  color: var(--matterColorAnti) !important;
}
.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--matterColorLight);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 12px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 50px;
  }
}

.previousButton {
  width: 241px;

  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorDarkGray);
  margin-right: 0px;
  margin-bottom: 24px;
  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 24px;
    margin-right: 15px;
  }
}

.previousButton:hover,
.previousButton:focus {
  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorDarkGray);
  box-shadow: none;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0px 30px;
  @media (--viewportLarge) {
    margin: 0px;
  }
}

.buttonWrapper > button {
  width: 50%;
  font-size: 14px;
  margin-right: 10px;
  @media (--viewportLarge) {
    width: 269px;
    font-size: 16px;
  }
}

.buttonWrapper > button:last-child {
  width: 50%;
  margin-right: 0px;
  @media (--viewportLarge) {
    width: 269px;
  }
}
