@import '../../styles/customMediaQueries.css';

.root {
  display: grid;
  grid-template-columns: 42% 23.5% 23.5%;
  grid-column-gap: 20px;
  padding: 0 15px;

  @media (--viewportMedium) {
    grid-template-columns: 45% 24.5% 24.5%;
  }
  @media (--viewportLargeIpad) {
    grid-template-columns: 46% 47%;
    padding: 0px;
    margin-top: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .endDateCalenderMob > div > div > div > div > div:nth-child(2) {
    left: auto !important;
    right: 75%;
  }
}

.DailyBookingWrap {
  grid-template-columns: 46% 21.5% 21.5%;

  @media (--viewportMedium) {
    grid-template-columns: 47% 23.5% 23.5%;
  }

  @media (--viewportLargeIpad) {
    grid-template-columns: 46% 47%;
  }
}

.formRow {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  margin-top: 30px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    padding: 0;
    margin-top: 24px;
  }
}

.field {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;

  &:nth-child(2n) {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    width: calc(50% - 12px);

    &:nth-child(2n) {
      margin-top: unset;
    }
  }
}

.fieldDisabled {
  background: none;
  color: --matterColorNegative;
}

.fieldDateInput {
  flex-grow: 1;
}

/* .SingleDatePicker_picker{
  display: none;
} */

.fieldSelect {
  flex-grow: 1;
  margin-left: 0px;
  margin-right: 0px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 0px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%234A4A4A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%234A4A4A"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }

  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;

    &::after {
      left: 0;
      bottom: 17px;
    }
  }
}

.fieldSelectDisabled {
  composes: fieldSelect;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 0px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%23E6E6E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%23E6E6E6"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }
  @media (--viewportMedium) {
    &::after {
      left: 2px;
      bottom: 16px;
    }
  }

  @media (--viewportLargeIpad) {
    left: 24px;
  }
}

.select {
  position: relative;
  padding-left: 15px;
  padding-right: 10px;
  font-size: 14px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;
  border-bottom-width: 2px;

  @media (--viewportMedium) {
    border-bottom-width: 3px;
    font-size: 16px;
  }

  @media (--viewportLargeIpad) {
    padding-left: 24px;
    padding-right: 16px;
  }
}

.selectDisabled {
  composes: select;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fill-rule="evenodd"/></svg>');
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColorDark);
  fill: var(--matterColorDark);
}

/**
CSS for hiding the end date

If you want to show the field for the end date e.g. for enabling longer bookings,
you can remove the following code.
Remember to do required code changes also in FieldDateAndTimeInput.js!
**/

.field {
  width: auto;
  @media (--viewportMedium) {
    width: auto;
  }
}

.startDate {
  grid-column: unset;
  margin-bottom: 0px;

  @media (--viewportLargeIpad) {
    grid-column: 1 / 3;
    margin-bottom: 20px;
  }
}

.spinnerDiv {
  display: block;
  width: 205%;
  height: 337px;
  background: rgb(252 252 252 / 68%);
  position: absolute;
  z-index: 9;
  top: -507%;
  @media (--viewportLargeIpad) {
    top: 64px;
  }

  @media (--viewportMedium) {
    width: 100%;
  }
}

.DailyBookingWrap .spinnerDiv {
  top: -652%;
  width: 181%;
  @media (--viewportMedium) {
    top: -464%;
    width: 100%;
  }
  @media (--viewportLargeIpad) {
    top: 64px;
  }
}

.DailyBookingWrap .spinnerMob {
  top: -652%;
  width: 175%;
  left: -76%;
  @media (--viewportMedium) {
    top: -530%;
    width: 100%;
    left: unset;
  }
  @media (--viewportLargeIpad) {
    top: 64px;
  }
}

.spinner {
  margin: auto;
}

.calendarSpinner {
  position: absolute;
  top: 50%;
  z-index: 11;
  left: 0;
  right: 0;
}

.endDate {
  grid-column: 2 / 4;
  @media (--viewportLargeIpad) {
    grid-column: 1 / 3;
  }
}

.endDateHidden {
  display: none;
}

.lineBetween,
.lineBetweenDisabled {
  margin-top: 36px;
}
.lineBetweenDisabled {
  color: var(--matterColorNegative);
}

/**
CSS for hiding the end date above
**/
.hoursWarningMsg,
.hoursWarningWrap {
  display: block;
}

.hoursWarningWrap {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  right: 50%;
  margin: auto;
  width: 50%;
  background: #fff;
  padding: 24px;
  z-index: 10;
  word-break: break-word;
  box-shadow: var(--boxShadow);
  border-radius: 10px;
}

.hoursWarningWrap p {
  display: inline-block;
  cursor: pointer;
}

.yesWrap {
  background: var(--successColor);
  padding: 4px 10px;
  margin-right: 10px;
  color: #fff;
  border-radius: 5px;
}

.NoWrap {
  background: var(--failColor);
  padding: 4px 10px;
  color: #fff;
  border-radius: 5px;
}

.hoursWarningWrap p svg {
  margin-right: 5px;
}

.backDrop {
  background: #000000f2;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.popUpwrap {
  display: block;
}

.hoursWarningMaxMsg {
  color: var(--failColor);
}
