@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--matterColorNegative);
}
.ammenitiesAcc {
  padding: 10px;
}
.test {
  padding: 5px;
}
.filterLabel,
.filterLabelSelected {
  composes: h3 from global;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 0px;
  padding: 4px 0 2px 0px;

  @media (--viewportMedium) {
    padding: 4px 0 2px 10px;
  }
}

.filterLabel {
  color: var(--matterColorDark);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

@media only screen and (max-width: 767px) {
  .isOpen > .clearButton {
    display: block;
  }
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
  /* width: 100%;
  position: relative; */
}

.labelButton::after {
  @media (--viewportMedium) {
    content: '';
    position: absolute;
    right: 0;
    top: 23px;
    border: solid #757575;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;

    -webkit-transform: rotate(140deg);
    transform: rotate(140deg);

    -webkit-transform: rotate(135deg);
    transform: rotate(45deg);

    transition: all 0.3s;
  }
}

.activeaccordin::after {
  @media (--viewportMedium) {
    transform: rotate(45deg);

    -webkit-transform: rotate(45deg);

    -webkit-transform: rotate(135deg);
  }
}

.clearButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.plain {
  width: 100%;
  display: none;
}

.isOpen {
  display: block;
}
