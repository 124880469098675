@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;
  color: #4a4a4a;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
  width: 100%;
}

.impfield {
  color: red;
}
.descriptionButton {
  margin-top: 40px !important;
}

.description,
.summary {
  composes: formMargins;
  flex-shrink: 0;
}

.certificate select optgroup {
  font-weight: 500;
}
.venueTypePlaceholder {
  color: var(--matterColorAnti) !important;
}
.certificate {
  composes: formMargins;
}

.venueTypeOptions {
  font-weight: 700 !important;
  color: var(--matterColorDark);
}

.venueTypeOptions option {
  color: var(--matterColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  width: 60% !important;
  margin: auto !important;
  margin-bottom: 30px !important;
  margin-top: 24px !important;

  @media (--viewportLarge) {
    display: inline-block;
    width: 250px !important;
    margin-top: 50px !important;
    margin-left: 0px !important;
  }
}

.ytWrapper {
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.videoChildWrapper > div {
  max-width: 100%;
  width: 80%;
}

.videoChildWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.venuetype {
  display: block;
}
