@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 50px;
  }
}

.tagsInput > div > span > span {
  background-color: var(--bannedColorDark) !important;
  color: var(--matterColorAlmostDark) !important;
  padding: 10px;
  margin-right: 7px;
  margin-bottom: 10px;
}
.tagsInput > div > span > span > a:hover {
  text-decoration: none;
}
.tagsInput > div > span > span > a {
  vertical-align: top;
  color: var(--matterColorDark);
  font-size: 25px;
  margin-left: 5px;
}

.tagsInput > div > span > span > a::before {
  content: ' ×';
}
.tooltip {
  visibility: hidden; /* Or display: none, depending on how you want it to behave */
  position: absolute;
  left: 60px;
}
@media only screen and (max-width: 767px) {
  .tooltipOnHover:hover + .tooltip {
    width: 70% !important;
  }
}
.tooltipOnHover:hover + .tooltip {
  /* Uses the adjacent sibling selector */
  visibility: visible; /* Or display: block */
  margin-left: 12px;
  width: 50%;
  background-color: var(--matterColorNegative);
  font-size: 14px;
  padding: 10px;
  font-weight: 200;
}
.tagsWithToolTip {
  display: flex;
  flex-direction: row;
  position: relative;
}
.tagsTooltip {
  display: flex;
  flex-direction: row;
  margin-left: -5px;
  margin-top: 5px;
  width: 15px;
}

.tagsInput > div {
  border: 1px solid #b2b2b2;
  padding: 15px;
}

.tagsInput > div > span {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tagsInput > div > span > input {
  margin-top: 20px;
}

.eventsAllowed > fieldset > ul > li:first-child {
  display: none;
}
.impFieldCss {
  color: #ff0000;
  font-size: 16px;
}

.videoWalkThroughCss {
  margin-top: 20px;
}

.eventsAllowed ul {
  margin-top: 10px;
}
.minMaxHoursTabs {
  display: grid;
  grid-template-columns: 50% 50%;
}

.formMargins {
  margin-bottom: 24px;
  color: #4a4a4a;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
  width: 100%;
}
.capacityHeading {
  margin-bottom: 0px;
}
.capacityInputField {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
}

.hoursInput {
  display: flex;
  flex-direction: column;
  @media (--viewportSmall) {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
  }
}

.minusIcon {
  color: #ff0000;
  margin-left: 10px;
  position: relative;
  top: -40px;
  align-self: end;
}

.virtualChildWrap {
  display: flex;
  width: 100%;
  align-items: baseline;
}

.ytDiv {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
}
.plusIcon {
  color: #059905;
  position: absolute;
  right: 0;
  top: -10px;
}

.previousButton {
  width: 241px;

  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorDarkGray);
  margin-right: 0px;
  margin-bottom: 24px;
  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 24px;
    margin-right: 15px;
  }
}

.previousButton:hover,
.previousButton:focus {
  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  color: var(--matterColorDarkGray);
  box-shadow: none;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0px 30px;
  @media (--viewportLarge) {
    margin: 0px;
  }
}

.buttonWrapper > button {
  width: 54%;
  font-size: 14px;
  margin-right: 10px;
  @media (--viewportLarge) {
    width: 269px;
    font-size: 16px;
  }
}

.buttonWrapper > button:last-child {
  width: 40%;
  margin-right: 0px;
  @media (--viewportLarge) {
    width: 269px;
  }
}

.hourLabel {
  font-size: 16px;
}

.maxFieldWrap {
  position: relative;
}

.maxFieldWrap > div > div + p {
  color: green;
}

.maxFieldWrap label{
  display: none;
}

.maxError {
  position: absolute;
  bottom: 0px;
  margin: 0px;
  color: var(--failColor);
  overflow: hidden;
  font-size: 14px;
}

.maxCapacityError > input {
  border-color: var(--failColor);
}
