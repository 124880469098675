@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColor);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    display: none;
    background-color: var(--matterColorLight);
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--Topbar_logoHeight)) / 2) 24px;
  padding-left: 5px;
}

.home > img {
  width: 52%;
  height: 100%;
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  /* Layout */
  display: flex;
  width: 58px;
  margin: 0;
  border-width: 0px;
  background-color: var(--marketplaceColor);
  padding: 10px;
  border-radius: 50%;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }

  @media (--viewportMedium) {
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
    width: 66px;
  }
}

.searchMenu > img,
.addSpace > img {
  width: 16px;
  margin: 0px;
  height: auto; 
}

.searchMenu {
  width: 48px;
}

.menu {
  padding: 15px 24px;
  position: relative;
}
.searchMenu {
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  @media (--viewportMedium) {
    padding: 12px 24px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

.userMenu,
.addSpace {
  display: flex;
  width: 35px;
  border-radius: 50%;
  padding: 10px;
  /* height: 21px; */
  align-items: center;
  min-height: auto;
  line-height: initial;
  font-weight: 700;
  margin-right: 10px;
  justify-content: center;
}

.addHrefIcon > .addSpace {
  width: 35px;
}

.userIcon {
  margin: 0px;
  line-height: inherit;
  font-weight: 700;
  font-size: 11px;
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.filterIcon {
  width: 55px;
}

.userMenu {
  width: 45px;
  height: 35px;
  line-height: normal;
}

.notificationDot {
  /* Dimensions */
  width: 6px;
  height: 6px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 13px;
  right: 17px;
  left: 40px;

  /* Style: red dot with white border */
  background-color: var(--failColor);
  border: 1px solid var(--matterColorLight);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);


  @media (--viewportMedium) {
    top: 17px;
    border: 2px solid var(--matterColorLight);
    width: 12px;
    height: 12px;
    left: unset;
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--matterColor);
}
.rootSearchIcon {
  stroke: var(--matterColor);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--matterColorAnti);
  margin: 20px 0 0;
  text-align: center;
  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.reminderModalLinkButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: 4px;
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.modalContainer {
  width: 100%;
  padding: 94px 0;
  margin: 0 24px;
}

.modalHeadingWrapper {
  padding-bottom: 31px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.modalHeading {
  composes: h1 from global;

  margin-top: 0;
  position: relative;
  left: 25px;
  @media (--viewportMedium) {
    left: 0px;
  }
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 40px;
}

.showListingsButton {
  margin-bottom: 30px;
}

.textCenter {
  text-align: center;
}

/* .filterPopUp{
  display: none;
} */


.humburgerWrap{
  display: block;
  margin-right: 15px;
  /* position: relative; */
}

.menuHumberger{
  color: #fff;
}

.menuListWrap{
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.9);
  color: #fff;
  width: 95%;
  margin: auto;
  padding: 10px 20px;
  z-index: -1;
  transform: translateY(-195px);
  transition: all 1s;
  border-top: 1px solid var(--marketplaceColor);
}

.menuListWrap > ul{
  margin: 5px 0px;
}

.menuListWrap > ul >li{
  padding: 8px 0px;
  font-weight: 600;
  font-size: 15px;
  position: relative;
}

.activeMenu{
  transform: translateY(15px);
}

.menuListWrap > ul >li > a {
  color: #fff;
}

/* Authentication */
.signupLink {
  color: #000 !important;
  border: 0;
  height: auto;
  line-height: 1;
  display: table;
  background-color: var(--attentionColor);
  padding: 9px 10px;
  color: #000;
  margin: 0px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  & .signup {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    display: table;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);

  &:hover {
    color: var(--matterColorLight);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.signup,
.login {
  composes: h5 from global;

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    margin: 28px 0;
  }
}