@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textarea {
  composes: h4 from global;
  border-bottom-width: 0;

  /* Avoid text going behind the submit button */
  padding: 22px 52px 25px 0;

  margin: 0;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: calc(100% - 52px);

  border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);
  background-color: transparent;

  @media (--viewportMedium) {
    margin: 0;
  }
  @media (--viewportLarge) {
    padding: 0 0 5px 0;
    margin: 0;
    width: 100%;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  display: flex;
  flex-direction: row;
  grid-column: 3 / 4;
  grid-row: 3;
  justify-content: flex-end;
  margin-left: 0px;
  @media (--viewportMedium) {
    margin-left: -10px;
    grid-row: 2;
  }
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.messageFieldText{
  margin-bottom: 10px;
}

.error {
  composes: h5 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  composes: button buttonText buttonBordersSecondary buttonColorsSecondary from global;

  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: var(--fontWeightMedium);
  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 120px;
  height: 41px;
  display: inline-block;
  margin: 5px 0 0 0;
  width: auto;
  background-color: var(--attentionColor);
  font-weight: bold;
  border-radius: 5px;
}

.sendIcon {
  margin: -3px 5px 0 0;
}

.sendMessageTextField label {
  display: block;
  height: 0px;
  width: 0px;
  opacity: 0;
  margin: 0px;
  line-height: unset;
  padding: 0px;
}

.sendMessageTextField .uploadLabel > img {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}

.sendMessageTextField .uploadLabel > p {
  margin: 0px;
  text-decoration: underline;
  font-weight: bold;
}

.sendMessageTextField .uploadLabel {
  display: inline-flex;
  align-items: center;
  height: auto;
  opacity: 1;
  line-height: initial;
  width: 100%;
  justify-content: flex-start;
  margin-left: 0px;
  @media (--viewportMedium) {
    justify-content: flex-end;
    margin-left: 0px;
  }
}



.uploadBtn {
  display: block;
  opacity: 0;
  height: 0px;
  width: 0px;
}

.buttonWrapper {
  display: grid;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  /* justify-content: space-between; */
  @media (--viewportMedium) {
    align-items: end;
    align-items: unset;
  }
}

.fileMsgWrap{
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: var(--matterColorAnti);
  & span{
    line-height: 20px;
  }
}

