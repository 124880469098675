@import '../../styles/customMediaQueries.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;
  justify-content: center;
  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    justify-content: unset;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.bookButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.openBookingForm p {
  /* Ensure that mobile button is over Footer too */
  background-color: var(--successColor);
  color: var(--matterColorLight);
  border: none;
  border-radius: var(--borderRadius);
  text-align: center;
  text-decoration: none;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  display: flex;
  width: 100%;
  min-height: 59px;
  margin: auto;
  padding: 17px 0 17px 0;
  transition: all var(--transitionStyleButton);
  cursor: pointer;
  position: relative;
  left: -10px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);
  justify-content: center;
  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
    margin-left: 0px;
    width: 95%;
    left: unset;
    position: unset;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.openBookingForm {
  display: flex;
  text-align: center;
  grid-column: 2 / 3;
  @media (--viewportLarge) {
    display: none;
    grid-column: unset;
  }
  @media (--viewportLargeIpad) {
    grid-column: unset;
  }
}

.btnDisabled p {
  display: block;
  background: #e6e6e6;
  pointer-events: none;
  background-color: #e6e6e6;
  color: var(--matterColorDarkGray);
}

.root {
  display: grid;
  grid-template-columns: 50% 50%;

  @media (--viewportMedium) {
    grid-template-columns: 50% 50%;
  }

  @media (--viewportLargeIpad) {
    display: flex;
    flex-direction: column;
  }
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 20px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
  grid-column: 1 / 3;
  @media (--viewportLargeIpad) {
    margin-bottom: 38px;
    grid-column: unset;
  }
}

.bookingScheduleContainer {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  flex-direction: row;
  margin-top: 5px;
  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 0px;
  }
  @media (--viewportSmall) {
    padding: 0px;
    margin-top: 0px;
  }
}

.hourlyRadioBtn {
  margin-right: 10px;

  @media (--viewportLargeIpad) {
    margin-right: 15px;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  composes: h4 from global;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;
  order: 3;
  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLargeIpad) {
    order: unset;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .priceValue {
    font-size: 24px !important;
    margin-left: 12px !important;
  }
}
.priceValue {
  color: var(--matterColorDark);
  font-weight: 700;
  margin-bottom: 0px;
  margin-right: 10px;
  font-size: 19px;
  margin-top: 0;
  margin-left: 14px;
  display: flex;
  align-items: flex-end;
  @media (--viewportMedium) {
    font-size: 24px;
  }

  @media (--viewportLargeIpad) {
    margin-left: 0px;
    font-size: 21px;
    margin: 0px 0px 10px;
    letter-spacing: -1px;
  }
  & span{
    line-height: normal;
    font-size: 14px;
    margin-right: 10px;
    font-weight: normal;
    font-style: italic;
    margin-bottom: 4px;
    letter-spacing: 0px;
  }
}

.priceRaidoBtnWrap {
  display: flex;
  flex-direction: column;
  order: 2;
  grid-row: 2;
  align-items: baseline;
  @media (--viewportLargeIpad) {
    order: unset;
    flex-direction: column;
    grid-row: unset;
    align-items: unset;
  }
}

.radioBtns label span:last-child {
  font-size: 16px;

  @media (--viewportMedium) {
    font-size: 20px;
  }

  @media (--viewportLargeIpad) {
    font-size: 16px;
  }
}

.radioBtns label span:first-child {
  margin-top: -2px;
  margin-right: 3px;

  @media (--viewportMedium) {
    margin-top: -2px;
    margin-right: 12px;
  }
}

.radioBtns label {
  padding-top: 0px;
  @media (--viewportLargeIpad) {
    padding-top: 6px;
  }
}

.activeRadio > label > span:last-child {
  font-weight: 700;
}
