@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  position: relative;
}

.longInfo {
  flex-wrap: wrap;
}

.event_item {
  display: inline-flex !important;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.embla {
  position: relative;
  margin-bottom: 15px;
  /* padding: 0px 36px 30px 36px; */
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: calc(1rem * -1);
  margin-bottom: 25px;
}
.embla__slide {
  flex: 0 0 116px;
  min-width: 0;
  position: relative;
  padding-left: 5px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: contrast(0.1);
  cursor: pointer;
  text-align: center;

  @media (--viewportMedium) {
    flex: 0 0 122px;
  }

  @media (--viewportLargeIpad) {
    flex: 0 0 113px;
  }

  @media (--viewportLargeWithPaddings) {
    flex: 0 0 12.6%;
  }
  @media only screen and (min-width: 1440px) {
    flex: 0 0 11%;
  }
}

.embla__slide > img {
  width: 44%;
  object-fit: cover;
}

.embla__slide > p {
  margin-bottom: 0px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 18px;
  font-family: var(--fontFamilyOpenSans);
  font-weight: 700;
  white-space: pre-line;
  padding: 0px 0px 0px 0px;

  @media (--viewportMedium) {
    font-size: 11px;
  }

  @media (--viewportLargeIpad) {
    font-size: 13px;
  }
}

.prevBtn {
  color: #b3b3b3 !important;
  background: transparent !important;
  position: absolute;
  left: 5px;
  transform: translate(-50%, -50%);
  top: 30%;
  cursor: pointer;
  width: 23px;
}

.nextBtn {
  color: #b3b3b3 !important;
  background: transparent !important;
  position: absolute;
  right: 5px;
  transform: translate(50%, -50%);
  top: 30%;
  left: unset;
  cursor: pointer;
  width: 23px;
}

.eventsCarousel button:nth-child(2) {
  left: -10px;
  transform: translate(-50%, -50%);
  top: 40%;
}
.eventsCarousel button:nth-child(3) {
  right: -10px;
  transform: translate(50%, -50%);
  top: 40%;
}

.eventsCarousel button:disabled {
  opacity: 0;
}

.eventsCarousel svg {
  color: #b3b3b3;
}

.eventActive {
  filter: contrast(1);
}

.eventsDots,
.eventsDots > button {
  height: 0;
  width: 0;
}

@media only screen and (max-width: 767px) {
  .embla__container {
    margin-bottom: 0px;
  }
}
