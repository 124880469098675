@import '../../styles/customMediaQueries.css';

.root {
  margin: 0 0 20px;
}

.reviewItem {
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.avatar {
  flex-shrink: 0;
  margin-right: 22px;
}

.reviewContent {
  composes: h4 from global;
  font-style: normal;
  margin: 3px 0 0;
  white-space: pre-line;
}

.reviewInfo {
  composes: h5 from global;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 16px;
  color: var(--matterColor);
  font-weight: 700;
  @media (--viewportMedium) {
    margin-top: 9px;
  }
}
@media only screen and (max-width: 767px) {
  .separator {
    font-weight: 600 !important;
    margin-top: 8px !important;
  }
  .reviewItem {
    margin-bottom: 15px;
  }
}

.separator {
  margin-top: 15px;
  margin-left: 0px;
  font-size: 15px;
  font-weight: 100;
  color: #b9b9b9;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  /* display: block; */
  margin-top: 3px;
  margin-bottom: 9px;
  display: none;
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 4px;
  white-space: nowrap;
  left: 10px;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.reviewAvatar {
  width: 60px;
  height: 60px;
}

.reviewAvatar > span {
  font-size: 20px;
}

.showListBtn {
  color: var(--successColorLightDark);
  font-weight: 700;
  cursor: pointer;
}

ul.active {
  max-height: 100%;
}
