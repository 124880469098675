@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.input {
  margin-bottom: 20px;
  & label,
  & input {
    color: var(--matterColorLight);
  }

  & input {
    border-bottom-color: var(--attentionColor);
    padding-bottom: 8px;
    @media only screen and (max-width: 767px) {
      font-size: 15px;
    }
    & + div {
      font-size: 12px;
      line-height: 1.25;
    }
  }

  & label {
    margin-bottom: 3px;
    font-size: 16px;
  }
  & input::placeholder {
    color: #e6e6e696;
  }
  & input:-internal-autofill-selected {
    background-color: transparent !important;
  }

  & input:hover {
    border-bottom-color: var(--attentionColor);
  }
}
.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  /* composes: marketplaceModalBottomWrapper from global; */
  margin-top: 24px;
  @media (--viewportLarge) {
    margin-top: 0pc;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  /* composes: marketplaceModalBottomWrapperText from global; */
  display: table;
  margin: 0;
  margin-left: auto;
  line-height: 1;
  margin-top: -10px;
}

.recoveryLink {
  /* composes: marketplaceModalHelperLink from global; */
  color: var(--matterColorLight);
  font-weight: 600;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.termsForSignIn {
  composes: marketplaceModalHelperText from global;
  text-align: center;
  margin: 40px 0 30px;
}
.validateMsg {
  font-size: 12px;
  line-height: 1.25;
}
.submitBtn {
  &:disabled {
    color: var(--matterColorDark);
    background-color: var(--attentionColor);
  }
  &:not(:disabled),
  &:not(:disabled):hover {
    color: var(--matterColorDark);
    background-color: var(--attentionColor);
  }
}

.captacha{
  margin: 30px auto;
}
