.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.impfield {
  color: red;
}

.inputError {
  border-bottom-color: var(--failColor);
}
.inputFieldLabel {
  position: relative;
  & button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 35px;
    border: 0;
    cursor: pointer;
    color: var(--matterColorLight);
    -webkit-tap-highlight-color: transparent;
    & svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
