@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  flex-direction: column;
  /* display: none; */

  @media (--viewportLargeIpad) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;
  display: none;
  @media (--viewportLargeIpad) {
    display: flex;
    padding: 128px 0 82px 36px;
    flex-direction: column;
    overflow-x: auto;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;
    background: url('../../assets/Location-sidebar.jpg') center center;
    background-origin: border-box;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 128px 0 82px calc((100% - 1056px) / 2);
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.tab a,.tab a:hover {
  color: var(--matterColorLight);
}
.panel {
  flex-grow: 1;
  padding: 0 30px;
  @media (--viewportLargeIpad) {
    padding: 0px;
  }

  @media (--viewportLarge) {
    padding: 88px 36px 82px 59px;
    border-left: 1px solid var(--matterColorNegative);
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 88px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.stepperWrap {
  display: block;
  position: relative;
  margin-top: 30px;

  @media (--viewportLargeIpad) {
    display: none;
  }
}

.connecter_style {
  border-color: #000 !important;
}

.stepper__step {
  border: 1px solid #000 !important;
  cursor: default !important;
}

.stepper__step.active {
  border: 1px solid #fdd301 !important;
}

.embla {
  position: relative;
  margin-bottom: 15px;
}

.embla__viewport {
  overflow: hidden;
  padding-top: 5px;
}

.embla__container {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: calc(1rem * -1);
}
.embla__slide,
.embla__container > div,
.embla__container > p {
  flex: 0 0 17.9%;
  min-width: 0;
  position: relative;
  padding-left: 1rem;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  @media (--viewportMedium) {
    flex: 0 0 20.33%;
  }

  @media (--viewportLargeWithPaddings) {
    flex: 0 0 16.6%;
  }
}

/* .embla__container > div > div{
  position: relative;
} */

.embla__container > div > div > div {
  position: relative;
  width: fit-content;
  top: -55px;
  text-transform: capitalize;
  font-size: 11px;

  @media (--viewportMedium) {
    top: -60px;
  }
}

.prevBtn {
  position: absolute;
  top: 11px;
  left: -5px;
  display: none;
}

.nextBtn {
  position: absolute;
  top: 11px;
  right: -5px;
}

.stepWrap {
  display: flex;
  text-transform: capitalize;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.stepWrap p::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #9b9a9a;
  bottom: -2px;
  /* left: unset; */
  right: 0;
}

.stepWrap p {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 15.5px;
  padding-bottom: 10px;
  padding-left: 20px;
  /* left: 25px; */
}

.stepWrap p::before {
  content: '';
  position: absolute;
  bottom: -6px;
  width: 10px;
  height: 10px;
  background: #fff;
  border: 1px solid var(--matterColor);
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
}

.stepWrap p:first-child::after {
  right: 0;
  width: 50%;
}

.stepWrap p:last-child::after {
  left: 0;
  width: 50%;
}

.stepWrap .successedtab::before {
  background: var(--matterColor);
  border-color: transparent;
}

.currenttab::before{
  background: var(--marketplaceColor) !important;
}

.stepWrap .successedtab::after {
  width: 100% !important;
  right: -50% !important;
  z-index: 1;
}

.stepWrap .successedtab > a {
  color: var(--matterColor);
}

.stepWrap p a {
  font-weight: 600;
}

.stepWrap p a:hover {
  text-decoration: none;
}

.disabled a {
  pointer-events: none;
  cursor: default;
  color: var(--matterColorAnti);
}

.mobHeaderImage img{
  height: 200px;
  object-fit: cover;
  width: 100%;
  @media (--viewportMedium) {
    height: 300px;
  }
}

