.root {
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-bottom: 24px;
  & label {
    margin-bottom: 3px;
  }
  & input {
    padding-bottom: 8px;
  }
}

.submitBtn {
  &:disabled {
    color: var(--matterColorDark);
    background-color: var(--attentionColor);
  }
  &:not(:disabled),
  &:not(:disabled):hover {
    color: var(--matterColorDark);
    background-color: var(--attentionColor);
  }
}
